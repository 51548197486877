<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <span class="option_txt">공정현황</span>
        <div class="input_search">
          <input
            type="text"
            placeholder="공정명 검색"
            :value="search_value"
            @input="typing"
          />
          <button>
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="input_checkbox">
          <label
            for="checkbox428"
            class="chk_box"
            :class="{
              active: visibleDel,
            }"
          >
            <i class="fa fa-check"></i>
          </label>
          <input
            type="checkbox"
            id="checkbox428"
            @click="
              $store.commit('setShowDeleteProcessFlag', !visibleDel);
              selectRow(-1);
            "
          />
          <label for="checkbox428" class="label_txt">삭제된 공정 보기</label>
        </div>
      </div>
      <h6>조회수 : {{ filtered_process.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 5 : 4" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>공정명</th>
              <th>연동 설비</th>
              <th>설비 위치</th>
              <th>공정검사</th>
              <th v-show="managementMode">
                {{ visibleDel ? '복구' : '삭제' }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ps, index) in filtered_process"
              :key="ps.id"
              @click="selectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ ps.name }}</td>
              <td>
                {{
                  ps.machine_id != null
                    ? findInfoFromId(machines, ps.machine_id).name
                    : '연동되지 않음'
                }}
              </td>
              <td>
                {{
                  ps.machine_id != null
                    ? findInfoFromId(machines, ps.machine_id).machine_location
                    : '연동되지 않음'
                }}<span
                  v-show="ps.machine_id != null"
                  class="ico_admin"
                  @click="goMachineManagementPage"
                  >관리</span
                >
              </td>
              <td>
                {{
                  Object.keys(ps).includes('process_verification_id')
                    ? findInfoFromId(
                        verifications,
                        ps.process_verification_id,
                      ) != ''
                      ? findInfoFromId(
                          verifications,
                          ps.process_verification_id,
                        ).inspection_item
                      : '진행하지 않음'
                    : '진행하지 않음'
                }}
                <span
                  v-show="
                    Object.keys(ps).includes('process_verification_id') &&
                      findInfoFromId(
                        verifications,
                        ps.process_verification_id,
                      ) != ''
                  "
                  class="ico_admin"
                  @click="goVerificationManagementPage"
                  >관리</span
                >
              </td>
              <td v-show="managementMode && !visibleDel">
                <button class="tbl_delete_btn" @click="ShowModal()">
                  delete
                </button>
              </td>
              <td v-show="managementMode && visibleDel" class="tbl_restore">
                <button class="btn_tbl" @click="restoreProcess(ps)">
                  {{ `◀` }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>세부정보</h5>
        <button
          class="btn_sub1"
          v-show="selectedIndex != -1"
          v-if="isPermissionOn"
          @click="copyData()"
        >
          복사 후 신규등록
        </button>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div>
            <div class="input_text">
              <label class="require">공정명</label>
              <input
                type="text"
                placeholder="공정명"
                required
                v-model="managementData.name"
                :disabled="!managementMode || selectedIndex == -1"
              />
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>연동설비</label>
              <div>
                <my-selectric
                  v-if="showSelectric"
                  :id="'machine_selectric'"
                  :watch="managementData.machine_id"
                  :options="machine_options"
                  :commit="'setManagementDataMachineIdToProcess'"
                  :class="
                    managementMode && selectedIndex != -1 ? '' : 'disabled'
                  "
                >
                </my-selectric>
              </div>
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>설비위치</label>
              <input
                type="text"
                placeholder=""
                readonly
                :value="
                  getMachineInfo(managementData.machine_id).machine_location
                "
              />
            </div>
          </div>
          <div class="input_text">
            <label>공정검사</label>
            <my-local-selectric
              :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
              :id="'verificationSelect'"
              :options="processVerificationOptions"
              :watch="managementData.process_verification_id"
              @changeValue="
                $event => {
                  if ($event == 'new') {
                    showModal = true;
                  } else {
                    managementData.process_verification_id = $event;
                  }
                }
              "
            >
            </my-local-selectric>
          </div>
          <div class="input_checkbox">
            <label for="checkbox102" class="label_txt">포장공정</label>
            <label
              for="checkbox102"
              class="chk_box"
              :class="managementData.package_process_yn ? 'active' : ''"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox102"
              @click="
                managementData.package_process_yn = !managementData.package_process_yn
              "
              :disabled="!managementMode || selectedIndex == -1"
            />
          </div>
          <div class="btn_wrap">
            <button
              v-show="managementMode && selectedIndex != -1"
              type="button"
              class="btn_sub2"
              :disabled="isValidModify || !checkValid"
              @click="submitForm()"
            >
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteProcess()"
    ></two-button-modal>
    <new-verification
      v-if="showModal"
      :verification_type_id="2"
      @onclose="showModal = false"
    ></new-verification>
  </div>
</template>

<script>
import { clone } from '@/utils/func';
import MySelectric from '@/layouts/components/MySelectric';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import routes from '@/routes/routes';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';

// import { clone } from '@/utils/func';
import { mapGetters } from 'vuex';
import NewVerification from '@/layouts/components/NewVerification.vue';

export default {
  mixins: [ModalMixin, SpinnerMixin, FavoriteMixin],
  data() {
    return {
      search_value: '',
      showSelectric: false,

      //delete modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,

      //new seq verification modal
      showModal: false,
    };
  },
  components: {
    MySelectric,
    TwoButtonModal,
    NewVerification,
    MyLocalSelectric,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromProcessPage',
      managementData: 'getManagementDataFromProcess',
      machine_options: 'getMachinesForSelectric',
      machines: 'getMachines',
      selectedIndex: 'getSelectedIndexFromProcess',
      process: 'getVisibleProcess',
      del_process: 'getDeletedProcess',
      visibleDel: 'getShowDeleteProcessFlag',
      processVerificationOptions:
        'getSeqVerificationForSelectricFromQualityManagement',
      verifications: 'getBaseVerificationFromQualityManagementNotSort',
    }),

    filtered_process() {
      const Hangul = require('hangul-js');
      return this.lodash
        .clonedeep(this.visibleDel ? this.del_process : this.process)
        .filter(
          x =>
            x.name.includes(this.search_value) ||
            Hangul.d(x.name, true)
              .map(x => x[0])
              .join('')
              .includes(this.search_value),
        )
        .sort((a, b) => {
          return a.name < b.name ? -1 : a.name == b.name ? 0 : 1;
        });
    },
    checkValid() {
      if (
        this.managementData == undefined ||
        this.managementData == null ||
        this.managementData.name == undefined ||
        this.managementData.name == null ||
        this.managementData.name.trim() == ''
      ) {
        return false;
      } else {
        return true;
      }
    },
    isValidModify() {
      if (this.managementMode && this.selectedIndex != -1) {
        let modifyData = this.lodash.clonedeep(this.managementData);
        modifyData.name = modifyData.name.trim();
        modifyData = JSON.stringify(modifyData);
        const originData = JSON.stringify(
          this.filtered_process[this.selectedIndex],
        );
        if (modifyData == originData) {
          return true;
        } else return false;
      } else return true;
    },
    validName() {
      const processListChk = this.lodash
        .clonedeep(this.process)
        .find(
          x =>
            x.id != this.managementData.id &&
            x.name == this.managementData.name.trim(),
        );

      if (processListChk != undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async restoreProcess(ps) {
      this.showSpinner();
      await this.$store
        .dispatch('RESTORE_PROCESS', ps.id)
        .then(() => {
          this.$store.commit('setSelectedIndexToProcess', -1);
          this.$store.commit('setManagementDataToProcess', {
            name: '',
            detail: '',
            id: -1,
            machine_id: null,
          });
          this.FETCHS('FETCH_PROCESS', '공정');
        })
        .catch(() => {
          this.openOneButtonModal('복구실패', '공정 복구 중 오류 발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    goMachineManagementPage() {
      const goingPage = routes[0].children.find(x => x.path == '/code/common');
      if (goingPage != undefined) {
        goingPage.meta.tab = 3;
      }
      this.$router.push('/code/common');
    },
    goVerificationManagementPage() {
      const goingPage = routes[0].children.find(
        x => x.path == '/quality/management',
      );
      if (goingPage != undefined) {
        goingPage.meta.tab = 1;
      }
      this.$router.push('/quality/management');
    },
    getMachineInfo(id) {
      let hitValue = this.machines.find(x => x.id == id);
      return hitValue != undefined
        ? hitValue
        : { name: '', machine_location: '' };
    },
    typing(e) {
      this.search_value = e.target.value.trim();
      this.selectRow(-1);
    },
    copyData() {
      let cloneManagementData = clone(this.managementData);
      cloneManagementData.name = cloneManagementData.name + '_복사됨';
      this.$store.commit('setNewDataToProcess', cloneManagementData);
      this.$store.commit('setOpenTabIndexToProcessPage', 1);
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToProcess', index);
      if (index != -1) {
        this.$store.commit(
          'setManagementDataToProcess',
          this.lodash.clonedeep(this.filtered_process[index]),
        );
      } else {
        this.$store.commit('setManagementDataToProcess', {
          id: -1,
          name: '',
          detail: '',
          machine_id: null,
          package_process_yn: false,
        });
      }
    },

    async deleteProcess() {
      this.showSpinner();
      await this.$store
        .dispatch(
          'DELETE_PROCESS',
          this.filtered_process[this.selectedIndex].id,
        )
        .then(() => {
          this.$store.commit('setSelectedIndexToProcess', -1);
          this.$store.commit('setManagementDataToProcess', {
            name: '',
            detail: '',
            id: -1,
            machine_id: null,
          });
          this.FETCHS('FETCH_PROCESS', '공정');
        })
        .catch(() => {
          this.openOneButtonModal('삭제 중 오류', '공정 삭제 중 오류');
        })
        .finally(() => {
          this.CloseModal();
          this.hideSpinner();
        });
    },
    async submitForm() {
      if (this.checkValid && !this.validName) {
        this.showSpinner();
        let payload = this.lodash.clonedeep(this.managementData);
        payload.name = payload.name.trim();

        await this.$store
          .dispatch('UPDATE_PROCESS', payload)
          .then(() => {
            this.openOneButtonModal('수정 성공', '성공적으로 수정하였습니다.');
            this.FETCH('FETCH_PROCESS', '공정');
            this.selectRow(-1);
          })
          .catch(() => {
            this.openOneButtonModal('수정 중 오류', '공정 수정 중 오류');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (!this.checkValid) {
        this.openOneButtonModal('수정 불가', '공정명은 필수 입력값 입니다.');
      } else if (this.validName) {
        this.openOneButtonModal(
          '수정 불가',
          `같은 이름의 공정이 등록이 되어있습니다.<br />다른 공정명을 등록해주세요.`,
        );
      }
    },
    async FETCHS(apiName) {
      this.showSpinner();
      await this.$store
        .dispatch(apiName)
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '공정 관련 정보 로드 중 오류',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.process.length == 0) {
      await this.FETCHS('FETCH_PROCESS');
    }
    if (this.machine_options.length < 2) {
      await this.FETCHS('FETCH_MACHINE');
    }
    if (this.verifications.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_BASE_VERIFICATION', '공정검사');
    }
    if (
      this.managementData.id != undefined &&
      this.managementData.id != null &&
      this.managementData.id != -1
    ) {
      this.$store.commit(
        'setSelectedIndexToProcess',
        this.filtered_process.findIndex(x => x.id == this.managementData.id),
      );
    }
    this.showSelectric = true;
  },
};
</script>

<style></style>
