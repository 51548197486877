<template>
  <div class="article">
    <div class="aside_management_mode regist_mode">
      <div class="head">
        <h5>세부정보</h5>
        <!-- <button class="excel_btn">엑셀 저장</button> -->
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: !checkValid && baseInfo,
                }"
                >공정명</label
              >
              <input
                type="text"
                placeholder="공정명"
                required
                v-model="newData.name"
              />
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>연동설비</label>
              <div>
                <my-selectric
                  v-if="showSelectric"
                  :id="'machine_selectric'"
                  :watch="newData.machine_id"
                  :options="machine_options"
                  :commit="'setNewDataMachineIdToProcess'"
                >
                </my-selectric>
              </div>
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>설비위치</label>
              <input
                type="text"
                placeholder=""
                readonly
                :value="getMachineInfo(newData.machine_id).machine_location"
              />
            </div>
          </div>
          <div class="input_text">
            <label>공정검사</label>
            <my-local-selectric
              v-if="showSelectric"
              :id="'verificationSelect'"
              :options="processVerificationOptions"
              :watch="newData.process_verification_id"
              @changeValue="
                $event => {
                  if ($event == 'new') {
                    showModal = true;
                  } else {
                    newData.process_verification_id = $event;
                  }
                }
              "
            >
            </my-local-selectric>
          </div>
          <div class="input_checkbox">
            <label for="checkbox2" class="label_txt">포장공정</label>
            <label
              for="checkbox2"
              class="chk_box"
              :class="newData.package_process_yn ? 'active' : ''"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox2"
              @click="newData.package_process_yn = !newData.package_process_yn"
            />
          </div>
          <div class="btn_wrap">
            <button type="button" class="btn_sub2" @click="submitForm()">
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <new-verification
      v-if="showModal"
      :verification_type_id="2"
      @onclose="showModal = false"
    ></new-verification>
  </div>
</template>

<script>
import MySelectric from '@/layouts/components/MySelectric';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import NewVerification from '@/layouts/components/NewVerification.vue';
// import { clone } from '@/utils/func';
export default {
  mixins: [ModalMixin, SpinnerMixin, FETCH_MIXIN],
  data() {
    return {
      showSelectric: false,
      showModal: false,
      baseInfo: false,
    };
  },
  components: {
    MySelectric,
    MyLocalSelectric,
    NewVerification,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromProcessPage',
      newData: 'getNewDataFromProcess',
      machine_options: 'getMachinesForSelectric',
      machines: 'getMachines',
      selectedIndex: 'getSelectedIndexFromProcess',
      process: 'getProcess',
      processVerificationOptions:
        'getSeqVerificationForSelectricFromQualityManagement',
      verifications: 'getBaseVerificationFromQualityManagementNotSort',
    }),
    checkValid() {
      if (
        this.newData == undefined ||
        this.newData == null ||
        this.newData.name == undefined ||
        this.newData.name == null ||
        this.newData.name.trim() == ''
      ) {
        return false;
      } else {
        return true;
      }
    },
    validName() {
      const processListChk = this.lodash
        .clonedeep(this.process)
        .find(x => x.name == this.newData.name.trim());

      if (processListChk != undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getMachineInfo(id) {
      let hitValue = this.machines.find(x => x.id == id);
      return hitValue != undefined
        ? hitValue
        : { name: '', machine_location: '' };
    },
    async FETCHS(str) {
      this.showSpinner();
      await this.$store
        .dispatch(str)
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '자재 관련 정보 로드 중 오류',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async submitForm() {
      if (this.checkValid && !this.validName) {
        this.showSpinner();
        let payload = this.lodash.clonedeep(this.newData);
        payload.name = payload.name.trim();

        await this.$store
          .dispatch('INSERT_PROCESS', payload)
          .then(() => {
            this.openOneButtonModal('등록 성공', '성공적으로 등록하였습니다.');
            this.baseInfo = false;
            this.$store.commit('setNewDataToProcess', {
              name: '',
              detail: '',
              machine_id: null,
              package_process_yn: false,
              process_verification_id: null,
            });
            this.FETCHS('FETCH_PROCESS');
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('저장 중 에러', '원자재 저장 중 에러발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (!this.checkValid) {
        this.openOneButtonModal('수정 불가', '공정명은 필수 입력값 입니다.');
        this.baseInfo = true;
      } else if (this.validName) {
        this.openOneButtonModal(
          '등록 불가',
          `같은 이름의 공정이 등록이 되어있습니다.<br />다른 공정명을 등록해주세요.`,
        );
      }
    },
  },

  async created() {
    if (this.machine_options.length < 2) {
      await this.FETCHS('FETCH_MACHINE');
    }
    if (this.verifications.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_BASE_VERIFICATION', '공정검사');
    }

    this.showSelectric = true;
  },
};
</script>

<style></style>
