<template>
  <div class="modalPopup process_modal">
    <div class="modal_header">
      <h3 class="title">{{ verification_type_name + ' 신규 등록' }}</h3>
      <button class="modal_close" @click="ModalClose"></button>
    </div>
    <div class="modal_body">
      <div class="input_text">
        <label>검사항목</label>
        <input type="text" v-model="inspection_item" />
      </div>
      <div class="input_text">
        <label>합격기준</label>
        <input type="text" v-model="pass_standard" />
      </div>
      <div class="input_text">
        <label>검사기구</label>
        <input type="text" v-model="inspection_equipment" />
      </div>
      <div class="input_text">
        <label>검사시기</label>
        <input type="text" v-model="inspection_timing" />
      </div>
    </div>
    <div class="modal_footer">
      <label class="warning">{{ error_text }}</label>
      <button class="btn_sub2" @click="submitForm">
        등록
      </button>
    </div>
  </div>
</template>

<script>
import spinnerMixin from '@/mixins/spinner';
import modalMixin from '@/mixins/modal';
import fetchMixin from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  mixins: [spinnerMixin, modalMixin, fetchMixin, DRAG_MODAL_MIXIN],
  data() {
    return {
      inspection_item: null,
      inspection_timing: null,
      inspection_equipment: null,
      pass_standard: null,
      error_text: '',
    };
  },
  computed: {
    verification_type_name() {
      switch (this.verification_type_id) {
        case 1:
          return '원자재 검사';

        case 2:
          return '공정검사';

        case 4:
          return '제품검사';

        default:
          return '';
      }
    },
  },
  props: {
    verification_type_id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ModalClose() {
      this.$emit('onclose');
    },
    async submitForm() {
      if (this.inspection_item == null || this.inspection_item.length == 0) {
        this.error_text = '검사항목은 필수 입력값입니다.';
        return;
      } else {
        const payload = {
          inspection_item: this.inspection_item,
          inspection_equipment: this.inspection_equipment,
          inspection_timing: this.inspection_timing,
          pass_standard: this.pass_standard,
          verification_type_id: this.verification_type_id,
        };
        this.showSpinner();
        await this.$store
          .dispatch('INSERT_VERIFICATION', payload)
          .then(async () => {
            //fetch
            await this.FETCH('FETCH_BASE_VERIFICATION');
            this.$emit('onclose');
          })
          .catch(() => {
            this.openOneButtonModal('등록 실패', '등록 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
  },
};
</script>

<style scoped></style>
