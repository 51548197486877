<template>
  <div :id="id" class="select_wrap" :class="state">
    <div class="select" @click="open = !open" :class="{ active: open }">
      <span class="label">{{ findValue() }}</span>
      <button type="button"></button>
    </div>
    <div
      class="select_items"
      v-show="open"
      :class="{ active_scroll: options.length > 5 }"
    >
      <ul>
        <li
          v-for="option in options"
          :key="option.value"
          :class="option.value == watch ? 'selected' : ''"
          @click="changeValue(option.value)"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  props: {
    watch: {
      required: true,
    },
    options: {
      required: true,
    },
    commit: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    state: {
      type: String,
    },
    index: {
      type: Number,
    },
  },
  methods: {
    changeValue(value) {
      if (this.index != undefined) {
        this.$store.commit(
          this.commit,
          this.lodash.clonedeep({ index: this.index, value: value }),
        );
      } else {
        this.$store.commit(this.commit, this.lodash.clonedeep(value));
      }

      this.open = false;
      this.$emit('refresh', { index: this.index, value: value });
    },
    findValue() {
      const hit = this.options.find(x => x.value == this.watch);
      if (hit != undefined) {
        return hit.label;
      } else {
        this.changeValue(this.options[0].value);
      }
    },
  },
  mounted() {
    document.addEventListener(
      'click',
      function(event) {
        // If the click inside the element, do nothing
        if (
          event.target.closest(`#${this.id}`) &&
          !event.target.closest('.disabled')
        )
          return;
        // If the clicks outside the element, hide it!
        this.open = false;
      }.bind(this),
    );
  },
};
</script>

<style></style>
